<template>
	<section>
		<div class="review">
			<div class="sort-bar">
				<h4>คะแนนรีวิว</h4>
				<span>วันที่ให้คะแนน</span>
			</div>

			<div class="loading" v-if="!isResultReady">
				<loader-content :isDisplay="true"></loader-content>
			</div>

			<div class="vue-dragscoll mt-2" v-dragscroll.x @dragscrollstart="dragStart()" @dragscrollend="dragEnd()" @click.capture="dragClick($event)">
				<table>
					<tbody>
						<tr v-for="(item, index) in resultList" :key="index">
							<td>
								<div class="rating">
									<b-icon icon="star-fill"
										class="icon"
										:class="{ 'active': (index + 1) <= item.rating }"
										v-for="(star, index) in 5"
										:key="index">
									</b-icon>
								</div>
								<div class="user">
									<b-icon icon="person"></b-icon>
									{{ item.firstname }}
								</div>
							</td>
							<td>
								<strong>ข้อมูลรถยนต์:</strong>
								<span>{{ item.licenseNumber }} {{ item.licenseProvince }} {{ item.carBrand }} {{ item.carModel }}</span>
							</td>
							<td>{{ item.reviewDate }}</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
		
		<paging
			@page="changePage($event)"
			:activePage="activePage"
			:totalPage="totalPage"
			class="mt-4">
		</paging>	
	</section>
</template>

<script>
import SellerService from '@/services/sellerService';
import RouterPath from '@/router/path';
import Helper from '@/services/helper';
import MixinDragScroll from '@/mixins/dragScroll';

export default {
	mixins: [ MixinDragScroll ],
	data() {
		return {
			isResultReady: false,
			branchId: Helper.getBranchSellerId(),
			activePage: 1,
			totalPage: 0,
			resultList: []
		}
	},
	props: {
		selectedBranch: Number
	},
	watch: {
		selectedBranch(curr) {
			this.branchId = curr;
			this.getReviewList();
		}
	},
	mounted() {
		this.getReviewList();
	},
	methods: {
		async getReviewList() {
			let loader = this.$modalLoader.render();
			this.resultList = [];
			
			const param = {
				page: this.activePage,
				branchId: this.branchId
			};
            const result = await SellerService.getReviewList(param);

			if (result.status == 401) {
				this.$router.push(RouterPath.SELLER_LOGIN);
			}

			this.isResultReady = true;
			this.totalPage = result.data.totalPage;
			this.resultList = result.data.resultList;

            loader.hide();
		},
		changePage(page) {
			this.activePage = page;
			this.getReviewList();
		}
	}
}
</script>

<style lang="scss" scoped>
@import '@/theme/Variable.scss';

.review {
	.sort-bar {
		background: #ccc;
		color: #555;
		padding: 10px 15px;
		display: flex;
		align-items: center;
		justify-content: space-between;

		h4 {
			font-weight: bold;
			font-size: 16px;
			margin: 0;
		}

		span {
			font-size: 14px;
		}
	}

	.loading {
		height: 300px;
	}

	table {
        width: 100%;
        font-size: 13px;
		color: #555;

		tbody {
			td {
				border-bottom: 1px solid #999;
				padding: 15px 10px;
				min-width: 100px;

				span {
					margin-left: 10px;
				}
			}
		}
    }

	.rating {
		display: flex;
		margin-bottom: 15px;

		.icon {
			font-size: 16px;
			color: #bbb;
			margin-right: 10px;

			&.active {
				color: #ffaa00;
			}
		}
	}
}
</style>