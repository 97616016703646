<template>
	<div>
		<seller-branch-filter
			class="mb-3"
			labelText="รีวิวสาขา"
			@changeBranch="changeBranch($event)">
		</seller-branch-filter>

		<h2>วิเคราะห์ธุรกิจ - คะแนนรีวิว</h2>

		<seller-overall-review :selectedBranch="branchId"></seller-overall-review>
		<seller-review-list :selectedBranch="branchId" class="mt-4"></seller-review-list>
	</div>
</template>

<script>
import Helper from '@/services/helper';
import SellerBranchFilter from '@/components/seller/BranchFilter';
import SellerOverallReview from '@/components/seller/report/OverallReview';
import SellerReviewList from '@/components/seller/report/ReviewList';

export default {
	components: {
		SellerBranchFilter,
		SellerOverallReview,
		SellerReviewList
	},
	data() {
		return {
			branchId: Helper.getBranchSellerId(),hId: -1
		};
	},
	methods: {
		changeBranch(branchId) {
			this.branchId = branchId;
		}
	}
}
</script>

<style lang="scss" scoped>
@import '@/theme/Variable.scss';

h2 {
	font-size: 24px;
	font-family: 'Open Sans', 'Kanit';
	font-weight: bold;
	margin-bottom: 10px;
	color: #333;
}
</style>