<template>
	<div class="overall-review" v-if="fields">
		<div class="point">
			<h3>{{ fields.averageRating }}<span>/5</span></h3>
			<div class="rating mt-1">
                <b-icon icon="star-fill"
                    class="icon"
                    :class="{ 'active': (index + 1) <= fields.starRating }"
                    v-for="(star, index) in 5"
                    :key="index">
                </b-icon>
            </div>
			<div class="total">
				{{ fields.totalPoint }} คะแนน
			</div>
		</div>

		<div class="all-rating">
			<div class="item" v-for="(item, index) in ratingList" :key="index">
				<div class="rating">
					<b-icon icon="star-fill"
						class="icon small"
						:class="{ 'active': (index + 1) <= item.rate }"
						v-for="(star, index) in 5"
						:key="index">
					</b-icon>
				</div>
				<div class="point-bar">
					<div class="progress">
						<div class="progress-bar bg-warning" 
							role="progressbar"
							:style="{ width: getAverageVote(item.property) + '%' }" 
							:aria-valuenow="getAverageVote(item.property)" 
							aria-valuemin="0"
							aria-valuemax="100">
						</div>
					</div>

					<span>{{ fields.totalReview[item.property] }}</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import SellerService from '@/services/sellerService';
import Helper from '@/services/helper';

export default {
	data() {
		return {
			fields: null,
			branchId: Helper.getBranchSellerId(),
			ratingList: [
				{ property: 'star5', rate: 5 },
				{ property: 'star4', rate: 4 },
				{ property: 'star3', rate: 3 },
				{ property: 'star2', rate: 2 },
				{ property: 'star1', rate: 1 },
			]
		}
	},
	props: {
		selectedBranch: Number
	},
	watch: {
		selectedBranch(curr) {
			this.branchId = curr;
			this.getReviewList();
		}
	},
	mounted() {
		this.getOverallRating();
	},
	methods: {
		async getOverallRating() {
			let loader = this.$modalLoader.render();
			const result = await SellerService.getOverallRating(this.branchId);

			if (result.data) {
				this.fields = result.data;
			}

			loader.hide();
		},
		getAverageVote(property) {
			let percent = 0;

			const sumReview = this.fields.totalReview.star5 + this.fields.totalReview.star4 + this.fields.totalReview.star3 + this.fields.totalReview.star2 + this.fields.totalReview.star1;

			if (sumReview > 0) {
				percent = Math.round((this.fields.totalReview[property] * 100) / sumReview);
			}

			return percent;
		}
	}
}
</script>

<style lang="scss" scoped>
@import '@/theme/Variable.scss';

.overall-review {
	display: flex;

	@media only screen and (max-width: $screenExtraSmall) {
		display: block;
	}

	.point {
		margin-right: 50px;

		@media only screen and (max-width: $screenSmall) {
			margin-right: 30px;
		}

		@media only screen and (max-width: $screenExtraSmall) {
			margin-top: 20px;
			margin-right: 0;
		}

		h3 {
			font-size: 72px;
			font-weight: bold;

			span {
				font-size: 44px;
				font-weight: normal;
			}
		}

		.total {
			margin-top: 20px;
			font-size: 18px;
		}
	}

	.all-rating {
		.item {
			display: flex;
			align-items: center;
			margin-bottom: 7px;

			@media only screen and (max-width: $screenExtraSmall) {
				margin-bottom: 3px;
			}

			.point-bar {
				display: flex;
				align-items: center;

				.progress {
					margin: 0 15px;
					width: 240px;

					@media only screen and (max-width: $screenSmall) {
						width: 150px;
					}
				}
			}

			span {
				font-size: 20px;

				@media only screen and (max-width: $screenExtraSmall) {
					font-size: 14px;
				}
			}
		}
	}

	.rating {
		display: flex;

		.icon {
			font-size: 32px;
			color: #bbb;
			margin-right: 10px;

			@media only screen and (max-width: $screenSmall) {
				margin-right: 5px;
			}

			&.small {
				font-size: 22px;

				@media only screen and (max-width: $screenSmall) {
					font-size: 16px;
					margin-right: 3px;
				}
			}

			&.active {
				color: #ffaa00;
			}
		}
	}
}
</style>